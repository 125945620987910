import React, { createElement } from "react";
import classnames from "classnames";
import styles from "./text-block.module.scss";
import Link from "@/components/Link";
import {
  render,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_UL,
  NODE_OL,
} from "storyblok-rich-text-react-renderer";
import { TextBlockStoryblok } from "@/types/component-types-sb";

const textClasses = {
  subtitle: "ef-text-subtitle",
  subtitle_small: "ef-text-subtitle-small",
  paragraph: "ef-text-para",
  "paragraph-light": "ef-text-para -light",
  "paragraph-light-small": "ef-text-para -light -small",
  "ui-label": "ef-text-ui",
  "ui-label-light": "ef-text-ui -light",
  caption: "ef-text-caption",
};

const TextBlock = (props: TextBlockStoryblok) => {
  const {
    as = "div",
    text_style,
    text_align = "",
    text_align_l = "",
    text_color,
    content,
    max_lines,
    className,
    children,
    customParagraphClass,
    style,
  } = props;
  const textClass = (name: keyof typeof textClasses) =>
    textClasses[name] || customParagraphClass || "ef-text-para -light";
  const alignClasses = classnames(styles[text_align], styles[text_align_l]);

  const textColor = text_color === "light" ? "-inv" : "";
  const textStyle = textClass(text_style as keyof typeof textClasses);
  const paragraphClasses = classnames(
    textStyle,
    alignClasses,
    textColor,
    "-no-max-width"
  );

  const maxLines = (numLines: string | undefined) => ({
    display: "-webkit-box",
    WebkitLineClamp: numLines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  });

  const paragraphRenderer = (children: React.ReactNode) => (
    <p className={`${paragraphClasses}`}>{children}</p>
  );

  const olRenderer = (children: React.ReactNode) => (
    <ol className={`ef-list ${paragraphClasses}`}>{children}</ol>
  );
  const ulRenderer = (children: React.ReactNode) => (
    <ul className={`ef-list ${paragraphClasses}`}>{children}</ul>
  );

  const linkRenderer = (
    children: React.ReactNode,
    props: { href?: string; target?: string }
  ) => {
    const { href, target } = props;

    return (
      <Link className="ef-link" target={target} href={href || ""}>
        {children}
      </Link>
    );
  };

  const textChildren = render(content, {
    markResolvers: {
      [MARK_LINK]: linkRenderer,
    },
    nodeResolvers: {
      [NODE_OL]: olRenderer,
      [NODE_UL]: ulRenderer,
      [NODE_PARAGRAPH]: paragraphRenderer,
    },
  });

  return createElement(
    as,
    {
      className: classnames("text-block", className),
      style: max_lines ? { ...maxLines(max_lines), ...style } : style,
    },
    textChildren,
    children
  );
};

export { TextBlock as default };
