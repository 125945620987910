import React from "react";
import SbEditable from "storyblok-react";
import TextBlock from "./text-block";
import styles from "./text-block.module.scss";
import classnames from "classnames";
import { TextBlockStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: TextBlockStoryblok;
  className?: string;
}

const SbTextBlock = (props: Props) => {
  const { blok, className } = props;
  const classes = classnames(styles.textBlock, className);

  return (
    // @ts-expect-error
    <SbEditable content={blok}>
      <TextBlock {...blok} className={classes} />
    </SbEditable>
  );
};

export default SbTextBlock;
